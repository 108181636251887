"use strict";

const header = {
    elements: {
        header: '.js-header',
        footer: '.js-header-footer',
        toggle: '.js-header-toggle',
        nav: '.js-header-nav',
        searchForm: '.js-header-search-form',
        searchToggle: '.js-header-search-toggle',
        searchModal: '.js-header-search-modal',
        searchSubmit: '.js-header-search-submit',
        searchInput: '.js-header-search-input',

        actives: {
            item: '.js-header-nav-item',
            parent: '.js-header-nav-parent',
        }
    },
    settings: {
        offset: 100,
    },
    init() {
        const self = this;
        self.check();

        window.addEventListener('scroll', function() {
            self.check();
        });
        

        document.querySelector(self.elements.toggle).addEventListener('click', function() {
            self.toggleNav();
        });

        document.querySelectorAll(self.elements.searchToggle).forEach(function(el) {
            el.addEventListener('click', function(e) {
                document.querySelector(self.elements.searchModal).classList.toggle('is-active');

                // e.preventDefault();
                console.log('click')

                const value = document.querySelector(self.elements.searchInput).value;

                if (value.length > 0) {
                    document.querySelector(self.elements.searchForm).submit(); //form submission
                    console.log('submit')
                } else {
                    e.preventDefault();
                }

            });
        });


        document.querySelector(self.elements.searchInput).addEventListener('keyup', function(e) {
            const $searchSubmit = document.querySelectorAll(self.elements.searchSubmit);
            const value = document.querySelector(self.elements.searchInput).value;

            $searchSubmit.forEach(function(el) {
                if (value.length > 0) {
                    el.classList.add('is-active');
                } else {
                    el.classList.remove('is-active');
                }
            });
        });
    },

    check() {
        const self = this;
        const $header = document.querySelector(self.elements.header);

        if (window.scrollY > self.settings.offset) {
            $header.classList.add('is-scrolled');
        } else {
            $header.classList.remove('is-scrolled');
        }
    },

    toggleNav() {
        const self = this;
        document.querySelector(self.elements.nav).classList.toggle('is-active');
        document.querySelector(self.elements.toggle).classList.toggle('is-active');
        self.adjustNavPosition();
        document.body.classList.toggle('is-locked');
    },
    
    closeNav() {
        const self = this;
        document.querySelector(self.elements.nav).classList.remove('is-active');
        document.querySelector(self.elements.toggle).classList.remove('is-active');

        document.body.classList.remove('is-locked');
    },

    adjustNavPosition() {
        const self = this,
            $header = document.querySelector(self.elements.header),
            $footer = document.querySelector(self.elements.footer),
            $nav = document.querySelector(self.elements.nav);

        const top = $footer ? $footer.getBoundingClientRect().bottom : $header.getBoundingClientRect().bottom;

        $nav.style.top = (top - 7) + 'px';
    },
    actives: {
        manage() {
            const self = header;

            const $items = document.querySelectorAll(self.elements.actives.item);

            $items.forEach(function(el) {
                el.addEventListener('click', function(evt) {
                    const parentSlug = el.getAttribute('data-parent-slug');
                    
                    // Handle actives
                    document.querySelectorAll(self.elements.actives.item).forEach(function(other) {
                        other.classList.remove('is-active');
                    });
                    el.classList.add('is-active');

                    // Handle parents
                    const $parent = document.querySelector(self.elements.actives.parent + '[data-slug="' + parentSlug + '"]');
                    
                    document.querySelectorAll(self.elements.actives.parent).forEach(function(other) {
                        other.classList.remove('with-active-children');
                    });

                    if ($parent) $parent.classList.add('with-active-children');

                });
            });
        },
        detect() {
            const self = header;

            const $items = document.querySelectorAll(self.elements.actives.item);
            // Clear actives
            $items.forEach(function(other) {
                other.classList.remove('is-active');
            });


            const activeReference = document.querySelector('[name="activeReference"]').value;

            // console.log('currentSlug', currentSlug);
            console.log('activeReference', activeReference);

            document.querySelectorAll('[data-active-reference="'+activeReference+'"]').forEach(function(other) {
                other.classList.add('is-active');

                // Find closest self.elements.actives.parent
                // const $parent = other.closest(self.elements.actives.parent);
                

                document.querySelectorAll(self.elements.actives.parent).forEach(function(other) {
                    other.classList.remove('with-active-children');
                });


                // Initialize a variable to store the closest parent
                if (!other.closest('ul')) return;
                const $parent = other.closest('ul').parentNode.querySelector(self.elements.actives.parent);
                console.log('$parent', $parent);


                if ($parent) $parent.classList.add('with-active-children');
            });

        }
    }
}

export default header