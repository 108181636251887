"use strict";

import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const slider = {
    instances: [],
    elements: {
        slider: '.js-slider',
        mobilePrev: '.js-slider-mobile-prev',
        mobileNext: '.js-slider-mobile-next',
    },


    mobilePrevClickHandler(instance) {
        instance.go('<');
    },

    mobileNextClickHandler(instance) {
        instance.go('>');
    },
    addPagination: function (instance, slider) {
        // You can add your class to the UL element
        const $pagination = slider.querySelector('.slider-pagination');

        if (!$pagination.classList.contains('is-ready')) {
            $pagination.classList.add('is-ready');

            const total = instance.Components.Elements.slides.length;

            // `items` contains all dot items
            $pagination.insertAdjacentHTML('beforeend', '<span class="slider-paginationArrow js-slider-mobile-prev"><svg viewBox="0 0 43 79" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.10811 39.552L40.4068 78L42 76.5452L4.18828 39.552L42 2.55871L40.4068 1L1.10811 39.552Z" fill="black" stroke="black"/></svg></span>');
            $pagination.insertAdjacentHTML('beforeend', '<span class="slider-paginationCounter">1</span>');

            $pagination.insertAdjacentHTML('beforeend', '<span class="slider-paginationTotal">/' + total + '</span>');
            $pagination.insertAdjacentHTML('beforeend', '<span class="slider-paginationArrow js-slider-mobile-next"><svg viewBox="0 0 43 79" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M42.0003 39.448L2.70159 1L1.1084 2.45479L38.9201 39.448L1.1084 76.4413L2.70159 78L42.0003 39.448Z" fill="black" stroke="black"/></svg></span>');
        }

        instance.on('move', function (new_index) {
            const $counter = slider.querySelector('.slider-paginationCounter');
            const $current = new_index + 1;
            $counter.innerHTML = $current;
        });

        const $mobilePrev = slider.querySelector('.js-slider-mobile-prev'),
            $mobileNext = slider.querySelector('.js-slider-mobile-next');
        
        if ($mobilePrev && $mobileNext) {
            $mobilePrev.removeEventListener('click', this.mobilePrevClickHandler(instance), false);
            $mobileNext.removeEventListener('click', this.mobileNextClickHandler(instance), false);
        }

        $mobilePrev.addEventListener('click', this.mobilePrevClickHandler(instance), false);
        $mobileNext.addEventListener('click', this.mobileNextClickHandler(instance), false);
    },
    init() {
        const self = this;
        self.instances = [];
        const sliders = document.querySelectorAll(self.elements.slider);

        if (sliders.length) {
            sliders.forEach((slider) => {
                self._init(slider);
            });
        }
    },
    _init(slider) {
        const self = this;
        const slug = slider.dataset.slug;

        console.log('slider init');

        switch (slug) {
            case 'slider':
                const autoplay = slider.dataset.autoplay ? slider.dataset.autoplay : false;

                self.instances[slug] = new Splide(slider, {
                    pagination: false,
                    autoplay: autoplay,
                    interval: autoplay ? 6000 : 0,
                    type: autoplay ? 'loop' : 'slide',
                    pauseOnHover: false,
                });

                self.instances[slug].on('mounted', function () {
                    self.addPagination(self.instances[slug], slider);
                });

                self.instances[slug].mount();
                break;

            case 'carousel':

                self.instances[slug] = new Splide(slider, {
                    pagination: false,
                    autoWidth: true,
                    drag: 'free',
                    arrows: false,
                    type: 'loop',
                    autoScroll: {
                        autoStart: false,
                        speed: 1,
                        pauseOnHover: false,
                        pauseOnFocus: false,
                    },
                    breakpoints: {
                        1024: {
                            perPage: 1,
                            drag: true,
                            autoWidth: false,
                            autoHeight: true,
                            autoScroll: false,
                            autoplay: true,
                        },
                    }
                });

                self.instances[slug].on('ready', function () {

                    self.addPagination(self.instances[slug], slider);

                    setTimeout(() => {
                        slider.onmouseenter = (event) => {
                            const windowWidth = window.innerWidth;
                            if (windowWidth > 1024) {
                                self.instances[slug].Components.AutoScroll.play();
                            } else {
                                self.instances[slug].Components.AutoScroll.pause();
                            }
                        };
                        slider.onmouseleave = (event) => {
                            const windowWidth = window.innerWidth;
                            self.instances[slug].Components.AutoScroll.pause();
                        };

                    }, 500);
                });

                self.instances[slug].mount({ AutoScroll });
                break;


            case 'navigation':
                self.instances[slug] = new Splide(slider, {
                    pagination: false,
                    autoWidth: true,
                    fixedHeight: '50px',
                    drag: 'free',
                    arrows: false,
                    focus: 'center',
                    start: slider.querySelector('.is-active') ? slider.querySelector('.is-active').dataset.index : 0,
                });

                self.instances[slug].mount();
                break;
        }
    },
    reset() {
        const self = this;
        const sliders = document.querySelectorAll(self.elements.slider);
        
        self.instances.forEach((instance) => {
            instance.destroy();
        });

        self.instances = [];

        if (sliders.length) {
            sliders.forEach((slider) => {
                self.removeListeners(slider);
                // Reset any relevant state or data as needed

                // Reinitialize the object/module
                self._init(slider);
            });
        }
    },
    removeListeners(slider) {
        /*
        const $mobilePrev = slider.querySelector('.js-slider-mobile-prev'),
            $mobileNext = slider.querySelector('.js-slider-mobile-next');

        if ($mobilePrev && $mobileNext) {
            $mobilePrev.removeEventListener('click', this.mobilePrevClickHandler(slider));
            $mobileNext.removeEventListener('click', this.mobileNextClickHandler(slider));
        }
        */
    }
};

export default slider;
