
"use strict";

const newsletter = {
    container: '.js-newsletter-container',
    element: '.js-newsletter',
    init: () => {
        const self = this;
        const $form = document.querySelector(newsletter.element);

        if (!$form) return;
        
        $form.onsubmit = (e) => {
            e.preventDefault();
            const self = newsletter;
            const formData = new FormData($form);

            $form.classList.add('opacity-50');
            $form.classList.add('pointer-events-none');

            // console.log(formData);
            fetch('/newsletter/subscribe', {
                method: 'post',
                body: formData
            })
            .then((r) => r.json())
            .then((r) => {
                if (r.success) {
                    self.message($form, 'success', r.msg);
                    
                } else {
                    self.message($form, 'error', r.msg);
                }

                $form.classList.remove('opacity-50');
                $form.classList.remove('pointer-events-none');

                $form.reset();
            })
            .catch((e) => {
                console.error(e);

                $form.classList.remove('opacity-50');
                $form.classList.remove('pointer-events-none');
            });
        };
    },
    message: ($form, type, msg) => {
        const $container = $form.closest(newsletter.container);
        
        const $message = document.createElement('div');
        $message.classList.add('mt-4');
        $message.classList.add('text-sm');
        $message.classList.add('text-right');

        /*
        if (type === 'success') {
            $message.classList.add('text-green-500');
        } else {
            $message.classList.add('text-red-500');
        }
        */

        $message.innerHTML = msg;

        $container.appendChild($message);

        setTimeout(() => {
            $message.remove();
        }, 5000);
    }
};

export default newsletter