"use strict";

const meta = {
    htmx: {
      elements: {

        langLink: '.js-htmx-lang-link',
      },
    },
    elements: {
      seo_title: '.js-seo-title',
      seo_description: '.js-seo-description',
      seo_link: '.js-seo-link',
    },
    values: {
      seo_title: null,
      seo_description: null,
    },
    update: function() {
      const self = this;
  
      const meta = {
        seo_title: document.querySelector(self.elements.seo_title).textContent,
      };

      const metaOgTitle = document.querySelector('meta[property="og:title"]'); // Get the <meta> element with the property "og:title"
      metaOgTitle.setAttribute("content", meta.seo_title); // Set the "content" attribute of the <meta> element to meta.seo_title
      document.querySelector('title').textContent = meta.seo_title; // Set the text content of the <title> element to meta.seo_title
  
      const metaOgUrl = document.querySelector('meta[property="og:url"]'); // Get the <meta> element with the property "og:url"
      metaOgUrl.setAttribute("content", window.location.href); // Set the "content" attribute of the <meta> element to the current URL
  
      self.values = meta;

      // Update language links
      const $langLink = document.querySelector(self.htmx.elements.langLink),
        language = $langLink.getAttribute('data-language'),
        $languageHrefElements = document.querySelectorAll(self.elements.seo_link);

      $languageHrefElements.forEach(function(el) {
        const dataLanguage = el.getAttribute('data-language');
        if (dataLanguage === language) {
          const href = el.getAttribute('value');
          $langLink.setAttribute('href', href);
        }
      });

    }
  };

  export default meta