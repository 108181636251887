import Headroom from "headroom.js";

const headroom = {
    element: document.querySelector('.js-headroom'),
    instance: null,
    init: function() {
        if (!this.element) {
            return;
        }

        this.instance = new Headroom(this.element);
        this.instance.init();
    }
};

export default headroom;