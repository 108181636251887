"use strict";

const navActives = {
    elements: {
        onClick: '.js-nav-actives-onclick',
        onScroll: '.js-nav-actives-onscroll',
        item: '.js-nav-actives-item',
    },
    scrollEventHandler: null, // Store the scroll event handler
    all() {
        const self = this;

        self.onClick();
        self.onScroll();
    },
    onClick() {

    },
    onScroll() {
        if (!document.querySelector(this.elements.onScroll)) return;

        // Define the scroll event handler as an arrow function
        this.scrollEventHandler = () => {

            if (!document.querySelector(this.elements.onScroll)) return;

            const scrollPosition = window.scrollY;
            const navItems = document.querySelectorAll(navActives.elements.item);

            let offsetAjusment = getComputedStyle(document.documentElement).getPropertyValue('--header-height-with-navigation-top-mobile');

            if (window.innerWidth >= 1024) {
                offsetAjusment = getComputedStyle(document.documentElement).getPropertyValue('--header-height-with-navigation-top-desktop');
            }

            offsetAjusment = (parseInt(offsetAjusment) * 16) + 75;

            navItems.forEach((item) => {
                const target = item.getAttribute('href');
                const targetElement = document.querySelector(target);

                if (targetElement === null) return;
                
                const targetPosition = targetElement.offsetTop - offsetAjusment;
                const targetHeight = targetElement.offsetHeight;

                if (targetPosition <= scrollPosition && (targetPosition + targetHeight) > scrollPosition) {
                    navItems.forEach((item) => {
                        item.classList.remove('is-active');
                    });
                    item.classList.add('is-active');
                } else {
                    item.classList.remove('is-active');
                }

                // if bottom of page, highlight last nav item
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                    navItems.forEach((item) => {
                        item.classList.remove('is-active');
                    });
                    navItems[navItems.length - 1].classList.add('is-active');
                }

            });
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', this.scrollEventHandler);
    },
    resetOnScroll() {
        // Remove the scroll event listener when resetting
        window.removeEventListener('scroll', this.scrollEventHandler);

        this.onScroll();
    }
};

export default navActives;
