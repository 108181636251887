import LazyLoad from 'vanilla-lazyload';

"use strict";

const lazy = {
    instance: null,
    init() {
        const self = this;
        self.instance = new LazyLoad({
            elements_selector: ".js-lazy:not(.is-loaded)",
            threshold: 1000,
            class_loaded: 'is-loaded',
            callback_loaded: function(el) {
                el.parentNode.classList.add('is-loaded');
            }
        });

        self.update();
    },
    update() {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        if (iOSSafari) {
            document.querySelectorAll('.media').forEach($media => {
                const $img = $media.querySelector('img');

                if (!$img) return;
                
                const srcset = $img.getAttribute('srcset');

                if (!srcset) return;
                
                $img.setAttribute('srcset', '');
                $img.setAttribute('srcset', srcset);
            });
        }
    },

    reset() {
        const self = this;
        // Remove any relevant event listeners or instance if needed
        if (self.instance) {
            self.instance.destroy();
            self.instance = null;
        }

        // Reset any relevant state or data as needed

        // Reinitialize the object/module
        self.init();
    }
};

export default lazy;
