const scrollInteractions = {
    elements: {
        si01: '.js-si-01',
    },
    init() {
        const self = this;

        window.addEventListener("scroll", self.scrollEvent);
    },
    reset() {
        const self = this;

        window.removeEventListener("scroll", self.scrollEvent);

        self.init();
    },
    scrollEvent() {
        const self = scrollInteractions;
        self.interactions.si01();
    },
    interactions: {
        si01() {
            const self = scrollInteractions,
                $si01 = document.querySelector(self.elements.si01);

            console.log('si01');
            if (!$si01) return;
            console.log('si01 here');

            $si01.classList.toggle('hidden', window.scrollY > 100);
        }
    },
};

export default scrollInteractions;