"use strict";

const cookieBanner = {
    elements: {
        text: '.js-cookieBanner-text',
        link: '.js-cookieBanner-link',
        linkLabel: '.js-cookieBanner-linkLabel',
        accept: '.js-cookieBanner-accept',
        reject: '.js-cookieBanner-reject',
    },
    init() {
        const self = this;
        const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');

		glowCookies.start('pt_BR', {
			style: 3,
			analytics: 'G-P2FT3LV5F0',
			policyLink: document.querySelector(self.elements.link).value,
            bannerLinkText: document.querySelector(self.elements.linkLabel).value,
            bannerDescription: document.querySelector(self.elements.text).value,
            acceptBtnText: document.querySelector(self.elements.accept).value,
            rejectBtnText: document.querySelector(self.elements.reject).value,
            border: 'none',
            bannerColor: '#fff',
            bannerBackground: '#151515',
            acceptBtnColor: '#151515',
            acceptBtnBackground: themeColor || '#fff',
            rejectBtnColor: '#fff',
            rejectBtnBackground: 'transparent',
		});
            
    }       

};

export default cookieBanner;