const scrolltop = {
    element: document.querySelector('.js-scrolltop'),
    init() {
        if (!this.element) {
            return;
        }

        this.element.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }
};

export default scrolltop;