"use strict";

const hoverImage = {
    activeImage: null,
    elements: {
        trigger: '.js-hover-image-trigger',
        target: '.js-hover-image-target',
    },
    mouseenterHandler: function (event) {
        const imageUrl = event.target.getAttribute('data-hover-image');
        if (imageUrl) {
            hoverImage.targetElement.src = imageUrl;
        }
    },
    init() {
        const self = this;
        const triggers = document.querySelectorAll(self.elements.trigger);
        self.targetElement = document.querySelector(self.elements.target);

        if (!triggers || !self.targetElement) {
            return;
        }

        self.mouseenterHandler = self.mouseenterHandler.bind(self);

        triggers.forEach((trigger) => {
            trigger.addEventListener('mouseenter', self.mouseenterHandler);
        });
    },

    reset() {
        const self = this;
        const triggers = document.querySelectorAll(self.elements.trigger);
        self.targetElement = document.querySelector(self.elements.target);

        if (!triggers || !self.targetElement) {
            return;
        }

        triggers.forEach((trigger) => {
            trigger.removeEventListener('mouseenter', self.mouseenterHandler);
        });

        // Reset any relevant state or data as needed

        // Reinitialize the object/module
        self.init();
    }

};

export default hoverImage;
