// source https://css-tricks.com/how-to-animate-the-details-element/
"use strict";

const popover = {
    elements: {
        trigger: '.js-popover-trigger',
    },
    init() {
        const self = this;

        document.querySelectorAll(self.elements.trigger).forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                el.classList.toggle('is-active');

                if (el.classList.contains('is-active')) {
                    // scroll to
                    // el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    
                }
            });
        });
    }
};
  
export default popover